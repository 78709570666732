import React, { useRef } from 'react'
import Ticker from "react-ticker"
import Slider from "react-slick"
import _ from 'lodash'

const Partners = ({ section, slidesToShow }) => {
  const sliderRef = useRef(null)

  if(!section) {
    return null;
  }

  const partners = _.get(section, "partners", [])

  const renderPartner = (partner, partnerIndex) => {
    // const partnerIndex = index - 1
    // const partner = partners[partnerIndex % partners.length]
    return (
      <div 
        className={ `Partners__media-item ${ partner.uuid }` }
        key={ `Partners__media-item-${ partnerIndex }` }>
          <img 
            className={ `Partners__media-item-image ${ partner.uuid }` }
            src={ partner.logo.url } 
            alt={ partner.name }
          />
      </div>
    )
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: (slidesToShow || 4),
    slidesToShow: (slidesToShow || 4),
    swipe: false,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 821,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          swipe: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          swipe: true
        }
      }
    ]
  }

  return (
    <div className="Partners">
      <h3 className="Partners__title small-header">
        { section.title }
      </h3>

      <div className="Partners__media-holder">
        <Slider { ...settings }>
          { _.orderBy(partners, "order").map(renderPartner) }
        </Slider>
      </div>
    </div>
  )   

}


export default Partners;
